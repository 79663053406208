import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {FormControl, FormHelperText, FormLabel, IconButton, Radio, RadioGroup, Tooltip} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";

interface FieldType {
    props: TemplateFieldType,
    isPreview: boolean,
    displayToolbar: string,
    setDisplayToolbar: any,
    fieldError: any,
    SaveFormValues: any,
    signUp: TypeSignUp,
    isEditor: boolean,
    updateField: any,
    moveFieldUp: any,
    moveFieldDown: any,
    deleteField: any,
    setSignUp: any,
    checkConsent: any
}

export const Field = ( {props, isPreview, displayToolbar, setDisplayToolbar, fieldError, SaveFormValues, signUp, isEditor, updateField, moveFieldUp, moveFieldDown, deleteField, setSignUp, checkConsent} : FieldType ) => {
    const fieldName = props.name.toLowerCase();
    const typesToBeProcessed =['YesNo', 'TextBox', 'TextArea', 'Checkbox','Text', 'Email', 'Radiobutton'];
    const processField = (typesToBeProcessed.includes(props.type) && props.name.indexOf('__') === -1 && props.name !== 'Submit' && isPreview);
    // @ts-ignore
    return (
        <Grid key={props._id} container>
            <Grid item xs={12}>

                {
                    props.type === 'YesNo' ?
                        <Box
                            key={props._id}
                            className="YesNo"
                            sx={{
                                display: 'flex',
                                mb: '10px'
                            }}
                        >
                            <FormControl
                                sx={{backgroundColor: 'none',
                                    width: '100%', mb:'10px!important'}}
                                onMouseOver={()=> {
                                    if (isPreview)
                                        setDisplayToolbar(fieldName)
                                }}
                                required={props.isRequired}
                                component="fieldset"
                                error={props._id ? fieldError[fieldName] : false} >
                                <FormLabel  component="legend"  sx={{fontSize: '1rem', pt: '10px',  paddingRight: '20px', pb: '10px'}}>{props.value}</FormLabel>
                                <RadioGroup
                                    name={fieldName}
                                    // @ts-ignore
                                    value={signUp?.fields[fieldName]}
                                    onChange={SaveFormValues}
                                >
                                    <FormControlLabel value='true' control={<Radio />} label={props.labelYes??'Yes'} />
                                    <FormControlLabel value='false' control={<Radio />} label={props.labelNo??'No'} />
                                </RadioGroup>
                                <FormHelperText>{fieldError[fieldName] ? "Mandatory field": ""}</FormHelperText>
                            </FormControl>
                        </Box>
                        : ''
                }
                {
                    (props.type === 'TextArea') &&
                    <TextField
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={3}
                        sx={{backgroundColor: displayToolbar === fieldName ? '#f7f7f7': '#FFFFFF', marginTop: '0px'}}
                        onMouseOver={()=>{
                            if (isPreview)
                                setDisplayToolbar(fieldName)
                        }}
                        label={props.value}
                        defaultValue={signUp?.fields[fieldName]}
                        required={props.isRequired}
                        name={fieldName}
                        error={fieldError[fieldName]}
                        helperText={fieldError[fieldName] ? "Mandatory field": ""}
                    />
                }
                {
                    (props.type === 'TextBox') && <TextField
                        variant="outlined"
                        fullWidth
                        minRows={3}
                        sx={{backgroundColor: displayToolbar === fieldName ? '#f7f7f7': '#FFFFFF', mb: '10px'}}
                        onMouseOver={()=>{
                            if (isPreview)
                                setDisplayToolbar(fieldName)
                        }}
                        defaultValue={signUp?.fields[fieldName]}
                        label={props.value}
                        required={props.isRequired}
                        name={fieldName}
                        error={fieldError[fieldName]}
                        helperText={fieldError[fieldName] ? "Mandatory field": ""}

                    />
                }
                {
                    (props.type === 'Checkbox') &&
                    <FormControl error={fieldError[fieldName]} required={props.isRequired}>
                        <FormControlLabel
                            sx={{backgroundColor: 'none', width: '100%', mb:'10px!important'}}
                            onMouseOver={()=>{
                                if (isPreview)
                                    setDisplayToolbar(fieldName)
                            }}

                            control={<Checkbox onChange={SaveFormValues} required={props.isRequired} checked={signUp?.fields[fieldName]} name={fieldName} />}
                            label={props.value}

                        />
                        <FormHelperText>{fieldError[fieldName] ? "Mandatory field": ""}</FormHelperText>
                    </FormControl>
                }
                {
                    (props.type === 'Radiobutton') &&
                    <FormControl error={fieldError[fieldName]} sx={{mb:'10px!important',mt:'10px!important'}} required={props.isRequired}>
                        <FormLabel id="demo-radio-buttons-group-label">{props.value}</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={fieldName}
                            value={signUp?.fields[fieldName]}
                            onChange={ (e) => {
                                    if (signUp?.limitation?.status === 'limitation-per-category' && signUp?.limitation?.details?.field?.toLowerCase() === fieldName) {
                                        const value = e.target.value;
                                        const tmp = JSON.parse(JSON.stringify(signUp));

                                        if(tmp.limitation.numRegistered.details[value] + 1 > tmp.limitation.details.values.find( (v: any) => v.value === value)?.max) {
                                            setSignUp( s => {
                                                return {
                                                    ...s,
                                                    limitation: {
                                                        ...s.limitation,
                                                        message: s.limitation.details.message,
                                                        isFull: true
                                                    }
                                                }

                                            })
                                        }
                                    }
                                }
                            }
                            onMouseOver={()=>{
                                if (isPreview)
                                    setDisplayToolbar(fieldName)
                            }}
                        >
                            {
                                props?.choices?.map( choice => <FormControlLabel  value={choice} control={<Radio  />} label={choice}/>)
                            }
                        </RadioGroup>
                        <FormHelperText>{fieldError[fieldName] ? "Mandatory field": ""}</FormHelperText>
                    </FormControl>
                }
                {
                    (props.type === 'Text' && props.name.indexOf('__') === -1 && props.name !== 'Submit') &&
                    <Typography
                        onMouseOver={()=>{
                            if (isPreview)
                                setDisplayToolbar(fieldName)
                        }}
                        variant="h4" sx={{textAlign: 'left', paddingLeft: '0px', pt: 1, mb:'10px!important'}}
                        dangerouslySetInnerHTML={{__html: props.value}}/>
                }
                {
                    (props.type === 'Email') &&
                    <TextField
                        required={props.isRequired}
                        fullWidth
                        variant="outlined"
                        sx={{backgroundColor: displayToolbar === fieldName ? '#f7f7f7': '#FFFFFF', mb: '10px'}}
                        onMouseOver={()=>{
                            if (isPreview)
                                setDisplayToolbar(fieldName)
                        }}
                        id={fieldName}
                        label={props.value}
                        defaultValue={signUp?.fields[fieldName]}
                        error={fieldError[fieldName]}
                        helperText={fieldError.email ? !signUp.email ? "Mandatory field": "Wrong format" : ""}
                        name={fieldName}
                        onBlur={(e) => checkConsent(e.target.value)}
                    />
                }
            </Grid>
            {
                processField && displayToolbar === fieldName  && isEditor &&
                <Grid item xs={12} sx={{textAlign: 'right'}} >
                    <Tooltip title="Edit">
                        <IconButton onClick={()=>updateField(props.name)}>
                            <EditIcon sx={{fontSize: 20}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Up">
                        <IconButton onClick={()=>moveFieldUp(props.name)}>
                            <ArrowUpwardIcon sx={{fontSize: 20}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Down">
                        <IconButton onClick={()=>moveFieldDown(props.name)}>
                            <ArrowDownwardIcon sx={{fontSize: 20}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton onClick={()=>deleteField(props.name)}>
                            <DeleteIcon sx={{fontSize: 20}}/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            }


        </Grid>
    )
}
